<template>
  <vx-card class="px-4 bg-white">
    <div class="con-exemple-prompt">
      <div class="flex justify-content-center mt-5 mb-5">
        <h3>افزایش اعتبار</h3>
      </div>
      <vs-input-number color="#cf2f60" min="80000"  v-model="amountCharge"  step="10000"/>
      <p class="text-center">تومان</p>
      <div class="primary-light-color p-3 rounded" :active="true" color="#3f2553" icon="new_releases" >
        <span> مبلغ قابل پرداخت با احتساب 10 درصد مالیات ارزش افرزوده <b class="text-success">{{realPrice | money}}</b> تومان</span>
      </div>
      <div class="mt-5 mb-2 flex w-full items-center justify-between">

        <div v-if="encteCode" class="flex w-7/12  justify-center items-center">
          <vs-input size="small" class="ml-2 w-7Z/12" v-model="offCode" />
          <vs-button @click="confirmOffCode" size="small" color="primary">اعمال</vs-button>
        </div>
      </div>
      <div v-if="offValueCalculate" class="mt-5 flex items-center justify-center ">
          <span class="text-sm font-medium m-2">
            افزایش اعتبار هایو بعد از پرداخت:
          </span>
        <vs-chip class="dana-font shadow-md m-2 animate-bounce" transparent color="success">
          <vs-avatar color="#cf2f60" text="هایو"/>
          <span class="dana-font text-black ">
              {{ offValueCalculate }}
            </span>
        </vs-chip>
      </div>
      <div class="flex justify-content-between mt-5">
        <router-link :to="{path: '/apps/paymentManage', query: {transaction : 'list'}}">
          <vs-button class="secondary-color">  تراکنش ها</vs-button>
        </router-link>
        <div>
          <vs-button @click="acceptAlert">پرداخت</vs-button>
        </div>
      </div>
      <br />
    </div>

  </vx-card>
</template>

<script>
export default {
  name: 'index',
  data () {
    return {
      Consume: '',
      ConsumeArr: [],
      sumCal : '',
      verifyoffCode:'',
      offCode: '',
      encteCode: '',
      userLevel:'',
      offPercent:'',
      offValueCalculate: '',
      render: false,
      Loading: false,
      amountCharge : 80000,
      activePrompt : false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  methods : {
    // CallDigit () {
    //   this.Consume = JSON.stringify(this.$store.state.DataConsume)
    //   JSON.parse(this.Consume).map((item) => {
    //     this.ConsumeArr.push(item)
    //   })
    //   this.sumCal = 0
    //   setTimeout(() => {
    //     this.$store.state.DataConsume[0].forEach((int) => {
    //       this.sumCal += Object.values(int)[0]
    //     })
    //   }, 500)
    // },
    acceptAlert () {
      this.$http.post('https://api.haio.ir/v1/invoice/charge/add', {
        amount: this.amountCharge,
        off_code: this.verifyoffCode,
        payment_side:'2',
        redirect_url: 'https://console.haio.ir/pages/payment'
      })
        .then(async (res) => {
          window.location = (res.data.params.payment_url)
          // console.log("Set follow txt record at your dns server:")
          // console.log(res.data.params)
        })
        .catch(error => {
          this.$vs.notify({
            color:'danger',
            title:' شارژ حساب',
            text:error.response.data.message
          })
        })
    },
    confirmOffCode () {
      this.$vs.loading()
      this.$http.post(`/sale/cooperation/code/validation?off_code=${this.offCode}`).then((res) => {
        if (res.data.code === 200) {
          this.$vs.loading.close()
          this.offPercent = res.data.params.off
          this.verifyoffCode = this.offCode
          this.offValueCalculate = this.amountCharge * this.offPercent / 100
          this.$vs.notify({text: 'کد تخفیف اعمال شد ', color: '#24c1a0'})
        }
      }).catch((err) => {
        this.$vs.loading.close()
        this.$vs.notify({text: err.response.data.message})
      })
    }
  },
  computed: {
    // CART DROPDOWN
    realPrice () {
      return ((this.amountCharge * 1) + (0.10 * this.amountCharge))
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    activeUser () {
      return this.$store.state.AppActiveUser
    }
  },
  mounted () {
    // this.Loading = true
    // setTimeout(() => {
    //   this.CallDigit()
    //   this.Loading = false
    // }, 1000)
    // if (this.sumCal > 0) {
    //   this.Loading = false
    //   this.render = true
    // }
  }
}
</script>

<style scoped>

</style>
